<template>
  <div class="d-flex align-items-center justify-content-between">
    <h1 class="fs-1 fw-bold text-primary-1">Bright Path</h1>
    <button
      @click="() => toggleModalContent(true)"
      class="btn text-secondary-1 border-secondary-1 bg-hover-light-secondary-1 py-2 px-3"
    >
      <i class="pi pi-plus"></i>
      Add new  content
    </button>
  </div>
  <div class="mt-4 grid-">
    <ProgressStudents />
    <library-level-component />
  </div>
</template>
<script>
import LibraryLevelComponent from "@/shared/components/Library/LibraryLevelComponent";
import useLibrary from "@/modules/admin/composables/MyLibrary/useLibrary";
import ProgressStudents from "@/modules/admin/components/MyLibrary/ProgressStudents";
import useContentAdd from "@/modules/admin/composables/MyLibrary/useContentAdd";
import { useStore } from "vuex"
import { onBeforeMount } from 'vue'
export default {
  components: {
    LibraryLevelComponent,
    ProgressStudents,
  },
  setup() {
    const store = useStore()
    const { toggleModalContent } = useContentAdd();
    const { loadServices } = useLibrary();
    loadServices();
    onBeforeMount(() => {
      store.commit("admins/previsualize", { data: {} , isView :false });
      store.commit("admins/contentAddPayload", { data: null , isEdit : false });
    });
    return {
      toggleModalContent
    }
  },
};
</script>

<style lang="scss" scoped>
.grid- {
  display: grid;
  grid-template-columns: 20rem 1fr;
  gap: 1rem;
}
</style>
