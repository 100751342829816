<template>
  <div
    class="
      bg-primary-5
      p-4
      py-4
      border-r-10
      d-flex
      flex-column
      justify-content-between
    "
  >
    <skeleton
      v-if="getCurrentLibrary.isLoading"
      borderRadius="10px"
      height="20rem"
      class="mb-3 skeleton-dark"
    />

    <div v-if="!getCurrentLibrary.isLoading" id="progress-student-content">
      <h2 class="text-primary-0 fs-4 fw-bold mb-3">
        Student progress <br />
        content
      </h2>
      <div
        v-for="(value, index) in dataProgress"
        :key="index"
        class="pt-3 pb-1 library-level"
        :class="value.class"
      >
        <h6 class="level-title fw-bold">
          {{ value.name === 'Not Started' ? 'Not started' :  value.name }}
        </h6>
        <div class="d-flex gap-2 align-items-center">
          <progress-bar
            :value="(value.value * 100) / value.total"
            :showValue="false"
            class="w-100"
            :class="value.progress_class"
          />
          <h6 style="font-size: 12px" class="m-0">
            {{ value.value }}/{{ value.total }}
          </h6>
        </div>
      </div>
      <button
        v-if="!getCurrentLibrary.isLoading"
        @click="() => toPdfProgress()"
        class="
          btn
          text-white
          bg-secondary-1 bg-hover-secondary-1
          mt-5
          py-2
          bl-shadow
          w-100
        "
      >
        Download chart
      </button>
    </div>
    <template v-if="getCurrentLibrary.isLoading">
      <div class="mb-4" v-for="item in 7" :key="item">
        <skeleton
          borderRadius="10px"
          height="1.2rem"
          class="mb-2 skeleton-dark"
        />
        <skeleton
          borderRadius="10px"
          height="1.6rem"
          class="mb-1 skeleton-dark"
        />
      </div>
    </template>
  </div>
</template>

<script>
import useCurrentLibrary from "@/shared/composables/Library/useCurrentLibrary";
import useLibrary from "@/modules/admin/composables/MyLibrary/useLibrary";
export default {
  name: "ProgressStudents",
  setup() {
    const { getCurrentLibrary } = useCurrentLibrary();
    const { progressStudents, dataProgress, toPdfProgress } = useLibrary();
    return {
      getCurrentLibrary,
      toPdfProgress,
      progressStudents,
      dataProgress,
    };
  },
};
</script>
